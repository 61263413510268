import React from "react"

import { Divider, Typography } from "@mui/material"

import { pageSetting, theme } from "../helpers/theme"
import { capitalFirstLetter } from "../helpers/common"

const SectionTitle = ({ title, style }) => {
  return (
    <Typography variant="h3" css={styles.root(style)}>
      {capitalFirstLetter(title)}
      <Divider variant="middle" sx={styles.dividerC} />
    </Typography>
  )
}

const styles = {
  root: style => ({
    marginTop: theme.gutterBig * 4,
    marginBottom: theme.gutterBig * 4,
    ...style,
    ...pageSetting.text,
  }),
  dividerC: {
    backgroundColor: pageSetting.attention,
    height: 4,
    borderRadius: 20,
    maxWidth: 100,
    margin: "auto",
    marginTop: 1,
    marginBottom: 2,
  },
}
export default SectionTitle
