import * as React from "react"
import ButtonPapper from "../components/ButtonPapper"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "../components/CarouselResponsive"
import { ofert } from "../helpers/common"
import { Box } from "@mui/system"

import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import WhyWe from "../components/WhyWe"
import GoogleMap from "../components/GoogleMap"

const IndexPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout>
      <Seo title="Start" />
      <Box sx={style.root}>
        <div css={{ width: matches ? "50%" : "100%" }}>
          <Slider size={{ xs: matchesXS, md: matches }} />
        </div>
        <div css={style.buttonGroup(matches)}>
          {ofert.map(o => (
            <ButtonPapper key={o.name} props={o} />
          ))}
        </div>
      </Box>
      <WhyWe />
      <GoogleMap />
    </Layout>
  )
}
const style = {
  root: theme => ({
    display: {
      sm: "block",
      lg: "flex",
    },
    width: "100%",
  }),
  buttonGroup: size => ({
    width: "100%",
  }),
}

export default IndexPage
