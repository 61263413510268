import React from "react"

import SectionTitle from "./SectionTitle"

import CustomCard from "./CustomCard"
import { PeopleIcon, CheckIcon, BankIcon } from "../helpers/Icons"
import { pageSetting, theme } from "../helpers/theme"

const props = { height: 40, width: 40, fill: pageSetting.attention }

const TEXT = [
  {
    icon: <PeopleIcon {...props} />,
    title: "profesjonalizm",
    text: "Jesteśmy certyfikowanym biurem reachunkowym (Licencja Ministra Finansów Nr 62959/2013) oraz członkiem Stowarzyszenia Księgowych Polskich",
  },
  {
    icon: <CheckIcon {...props} />,
    title: "doświadczenie",
    text: "Świadczymy usługi księgowo-podatkowe od ponad 10 lat obsługując ponad 150 firm różnej wielkości",
  },
  {
    icon: <BankIcon {...props} />,
    title: "bezpieczeństwo",
    text: "Posiadamy wysokie ubezpieczenie OC dla biur rachunkowych oraz wykorzystujemy nowoczesne oprogramowanie dla biur rachunkowych",
  },
]

const WhyWe = () => {
  return (
    <div css={styles.root}>
      <SectionTitle title="dlaczego my?" />
      <div css={styles.cardsBox}>
        {TEXT.map(obj => (
          <CustomCard
            key={obj.title}
            title={obj.title}
            icon={obj.icon}
            text={obj.text}
            style={{ fontColor: { fontWeight: 700 } }}
          />
        ))}
      </div>
    </div>
  )
}

const styles = {
  root: {
    marginTop: theme.gutterBig * 6,
  },
  sectionTitle: {
    marginBottom: theme.gutterBig * 4,
  },
  cardsBox: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  card: {
    height: 350,
    width: 320,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `${theme.gutterBig}px 0`,
    paddingTop: theme.gutterSmall,
  },
  title: {
    p: 2,
  },
}

export default WhyWe
