import { Button, Paper } from "@mui/material"
import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Link } from "gatsby"
import { palette } from "../helpers/theme"

const ButtonPapper = ({ children, props }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <Paper as={Link} to={props.link} elevation={3} sx={styles.root(matches)}>
      <Button sx={styles.button}>{children || props.name}</Button>
    </Paper>
  )
}

const styles = {
  root: size => ({
    width: "100%",
    m: size ? 1 : 0,
    mt: 1,
    p: 0,
    height: 102,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: palette.theme_navy,
    fontSize: 18,
    fontWeight: 700,
  }),
  button: {
    height: "100%",
    width: "100%",
    fontSize: 18,
    color: "inherit",
    fontWeight: "inherit",
  },
}

export default ButtonPapper
