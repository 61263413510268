import React from "react"

import { palette, theme } from "../helpers/theme"
import SectionTitle from "./SectionTitle"

const GoogleMap = ({ title = "Gdzie jesteśmy", style }) => {
  return (
    <div css={[styles.root, style]}>
      <SectionTitle title={title} />
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d646.1398352327492!2d20.951937884736157!3d49.62491170767924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473dc282a68a2aff%3A0x217c30e7281acd22!2sBIURO%20RACHUNKOWE%20%22PRO%20CALCULO%22!5e0!3m2!1sen!2spl!4v1644427803174!5m2!1sen!2spl"
        css={styles.map}
      ></iframe>
    </div>
  )
}

const styles = {
  root: {
    marginTop: theme.gutterBig * 6,
  },
  sectionTitle: {
    marginBottom: theme.gutterBig * 4,
  },
  map: {
    width: "100%",
    height: 450,
    border: 0,
    allowfullscreen: "",
    loading: "lazy",
    boxShadow: `0 0 3px ${palette.darkGrey}`,
  },
}

export default GoogleMap
