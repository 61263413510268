import React from "react"

import { CardMedia } from "@mui/material"

import photo1 from "../images/car1.jpg"
import photo2 from "../images/car2.jpg"
import photo3 from "../images/car3.jpg"
import photo4 from "../images/car4.jpg"
import photo5 from "../images/car5.jpg"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"

import "swiper/css"
import "swiper/css/autoplay"

const photoArray = [
  {
    url: photo4,
  },
  {
    url: photo3,
  },
  {
    url: photo2,
  },
  {
    url: photo1,
  },
  {
    url: photo5,
  },
]

const Item = props => {
  return <CardMedia component="img" image={props.image} css={styles.image} />
}

const CarouselResponsive = ({ size }) => {
  return (
    <Swiper
      modules={[Autoplay]}
      css={{display: 'flex', alignItems: 'center'}}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{ delay: 4000 }}
    >
      {photoArray.map(pic => (
        <SwiperSlide key={pic.url}>
          <Item image={pic.url} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const styles = {
  root: size => ({}),
  image: {
    borderRadius: 16,
    paddingTop: 7,
    // height: '100%',
  },
}

export default CarouselResponsive
